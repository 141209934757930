<template>
  <div>
    <div class="landing section">
      <div class="text-ctn">
        <b-row>
					<b-col sm="12" md="12" class="my-auto">
						<h1 class="mb-5">PARTY <span class="green">RE</span><span class="blue">VI</span><span class="red">VE</span></h1>
					</b-col>
          <b-col sm="12" md="12" class="mb-4">
            ¡Muchas gracias por tu compra!
          </b-col>
					<b-col sm="12" md="12" class="my-auto">
            Recibirás en breves un correo electrónico a la dirección que has indicado durante la compra.
						<br>
						Este correo contiene tu entrada en formato código QR. Recuerda que deberás presentarlo en puerta junto con algún documento de identidad.
						<br><br>
						<small>Si no encuentras el correo, por favor, revisa tu carpeta de correo no deseado y/o spam.</small><br>
						<small>Cualquier problema adicional, te atenderemos desde nuestra cuenta de instagram <a href="https://www.instagram.com/party_revive">@party_revive</a></small>
          </b-col>
        </b-row>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.text-ctn {
	text-align: center;
	margin-top: 10%;
	margin-bottom: 10%;
	font-size: 1.5rem;
	font-weight: 600;
	color: #fff;
	text-shadow: 0 0 10px rgba(0,0,0,0.5);
	background-color: rgba(1,1,1,0.5);
	box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
	padding: 2rem 1rem;
}
</style>